import { useTranslation } from "react-i18next";
import PoliceIcon from "@src/assets/CN/images/police.png";
import "../index.less";
import CNEnum from "@src/Locales/Constants/CNEnum";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import i18n from "@src/Basic/Language/i18n";

const FooterBottom = () => {
  const { t } = useTranslation();
  const { lang = "" } = useParams();
  const i18Language = useMemo(() => i18n.language, [i18n.language]);
  const handleNavigate = (path: string) => {
    window.open(path, "_blank");
  };

  const yearText = useMemo(() => {
    return new Date().getFullYear();
  }, []);

  return (
    <div className="footerBottom flex justify-between items-center">
      <div className="copyright flex">
        <span>
          Copyright © {yearText} {t(CNEnum.taoquxianCommpany)} |
        </span>
        <a className="link no-underline mx-[3px]" target="_blank" href="https://beian.miit.gov.cn/">
          {t(CNEnum.recordNumber)} |
        </a>
        <div className="pliceIcon"></div>
        <a
          className="link no-underline"
          style={{ fontWeight: 400 }}
          target="_blank"
          href="https://beian.mps.gov.cn/#/query/webSearch?code=11010802044619"
        >
          京公网安备11010802044619号
        </a>
      </div>

      <div className="flex agreement ">
        <span
          className="cursor-pointer"
          onClick={() => {
            handleNavigate(`/${lang || i18Language}/protocol`);
          }}
        >
          {t(CNEnum.serviceAgreement)}
        </span>
        <span
          className="cursor-pointer mx-[16PX]"
          onClick={() => {
            handleNavigate(`/${lang || i18Language}/privacy`);
          }}
        >
          {t(CNEnum.privacyPolicy)}
        </span>
        <a
          className="link no-underline cursor-pointer"
          target="_blank"
          href="https://static.restosuite.cn/privacy/insight_privacy.html "
        >
          RestoSuite Insight {t(CNEnum.privacyPolicy)}
        </a>
      </div>
    </div>
  );
};
export default FooterBottom;
