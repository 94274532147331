import CNEnum from "@src/Locales/Constants/CNEnum";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Mailbox } from "./assets/mailbox.svg";
import { ReactComponent as Platform } from "./assets/platform.svg";
import accountCode from "@src/assets/CN/images/acountCode.png";

const useTypeList = () => {
  const { t, i18n } = useTranslation();
  const typeList = useMemo(() => {
    return [
      // {
      //   title: t(CNEnum.businessType),
      //   key: "businessType",
      //   list: [
      //     {
      //       name: t(CNEnum.fastFood),
      //     },
      //     {
      //       name: t(CNEnum.dinner),
      //     },
      //     {
      //       name: t(CNEnum.teaDrink),
      //     },
      //     {
      //       name: t(CNEnum.hotPot),
      //     },
      //     {
      //       name: t(CNEnum.weigh),
      //     },
      //     {
      //       name: t(CNEnum.baking),
      //     },
      //     {
      //       name: t(CNEnum.bar),
      //     },
      //     {
      //       name: t(CNEnum.coffee),
      //     },
      //     {
      //       name: t(CNEnum.professionalServices),
      //     },
      //   ],
      // },
      // {
      //   title: t(CNEnum.resource),
      //   key: "resource",
      //   list: [
      //     {
      //       name: t(CNEnum.quotation),
      //     },
      //     // {
      //     //   name: t(CNEnum.selectReason),
      //     // },
      //     {
      //       name: t(CNEnum.support),
      //     },
      //     {
      //       name: t(CNEnum.developerPlatform),
      //       navigate: "develop",
      //     },
      //     // {
      //     //   name: t(CNEnum.community),
      //     // },
      //   ],
      // },
      {
        title: t(CNEnum.contact),
        key: "contact",
        list: [
          // {
          //   name: `${t(CNEnum.customerSupport)}：（086）400-6000`,
          //   icon: <Call />,
          // },
          // {
          //   name: `${t(CNEnum.sale)}：（086）470-1673`,
          //   icon: <User />,
          // },
          // {
          //   name: t(CNEnum.branchOffice),
          //   icon: <Address />,
          // },
          // {
          //   name: t(CNEnum.cooperativePartner),
          //   icon: <Partner />,
          // },
          // {
          //   name: t(CNEnum.developerPlatform),
          //   navigate: "develop",
          //   icon: <Platform />,
          // },
          {
            name: `${t(CNEnum.mailbox)}：sales@restosuite.cn`,
            icon: <Mailbox />,
          },
          {
            name: "官方公众号",
            type: "img",
            img: accountCode,
          },
        ],
      },
    ];
  }, [i18n.language]);

  return {
    typeList,
  };
};

export default useTypeList;
