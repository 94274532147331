import React, { useEffect, useState } from "react";
import { ReactComponent as ChevronDown } from "./assets/chevron-down.svg";
import { ReactComponent as ChevronRight } from "./assets/chevron-right.svg";
import useTypeList from "./useTypeList";
import GlobalConfig from "@src/GlobalConfig";
import { useNavigate } from "react-router-dom";
import "./index.less";

export default () => {
  const { typeList } = useTypeList();
  const navigator = useNavigate();
  const [list, setList] = useState(typeList);
  const handleExpand = (key: string) => {
    console.log("key", key);
    const newList = list.map((item: any) => {
      if (item.key === key) {
        item.isExpand = !item.isExpand;
      } else {
        item.isExpand = false;
      }
      return item;
    });
    setList(newList);
  };

  return (
    <div className="collapseWrap">
      {typeList.map((item: any) => {
        return (
          <div className="container">
            <div className="typeList">
              {item.list?.map((info: any) => {
                return (
                  <>
                    {info.type === "img" ? (
                      <div className="accountCodeWrap">
                        <img className="img" src={info.img} />
                        <div className="accountText">{info.name}</div>
                      </div>
                    ) : (
                      <div
                        className="typeItem"
                        onClick={() => {
                          if (info.navigate === "develop") {
                            GlobalConfig.pushOpenAPI();
                          }
                        }}
                      >
                        {info.icon}
                        <span className="ml-[4px]">{info.name}</span>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
