import { ReactComponent as Logo } from "./assets/white-logo.svg";
import Collapse from "./Collapse";

import { useTranslation } from "react-i18next";
import CNEnum from "@src/Locales/Constants/CNEnum";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import i18n from "@src/Basic/Language/i18n";
import "./index.less";

const MobileFooter = () => {
  const { t } = useTranslation();
  const { lang = "" } = useParams();
  const navigator = useNavigate();
  const i18Language = useMemo(() => i18n.language, [i18n.language]);
  const handleNavigate = (path: string) => {
    window.open(path, "_blank");
  };

  const yearText = useMemo(() => {
    return new Date().getFullYear();
  }, []);
  return (
    <div className="mobileFooter w-full">
      <div className="upper">
        <Logo />
      </div>
      <Collapse />
      <div className="mobileBottom">
        <div className="copyrightWrap">
          <span>
            Copyright © {yearText} {t(CNEnum.taoquxianCommpany)}
          </span>
          <div>
            <a
              className="link no-underline mx-[3px]"
              target="_blank"
              href="https://beian.miit.gov.cn/"
            >
              {t(CNEnum.recordNumber)}
            </a>
            <div className="flex justify-center">
              <div className="pliceIcon"></div>
              <a
                className="link no-underline"
                style={{ fontWeight: 400 }}
                target="_blank"
                href="https://beian.mps.gov.cn/#/query/webSearch?code=11010802044619"
              >
                京公网安备11010802044619号
              </a>
            </div>
          </div>
        </div>
        <div className="flex agreement">
          <span
            className="cursor-pointer"
            onClick={() => {
              navigator(`/${lang || i18Language}/protocol`);
            }}
          >
            {t(CNEnum.serviceAgreement)}
          </span>
          <span
            onClick={() => {
              navigator(`/${lang || i18Language}/privacy`);
            }}
            className="cursor-pointer privacyPolicy"
          >
            {t(CNEnum.privacyPolicy)}
          </span>
          <a
            className="link no-underline cursor-pointer"
            target="_blank"
            href="https://static.restosuite.cn/privacy/insight_privacy.html "
          >
            RestoSuite Insight {t(CNEnum.privacyPolicy)}
          </a>
        </div>
      </div>
    </div>
  );
};
export default MobileFooter;
