// 以 qrOrder 为开头
enum InterQrOrderEnum {
    // 再见等待
    // qrOrder.loading
    qrOrderFirstTitleItalic = "qrOrder.firstTitleItalic",
    qrOrderFirstTitleItalicAfterSpan = "qrOrder.bannerHeaderTitleItalicAfterSpan",
    qrOrderFirstTitleDesc = "qrOrder.firstTitleDesc",
    // 它彻底改变了你的客户与你的餐厅互动的方式。通过简单的二维码扫描，您的食客将能够访问互动菜单、查看菜单、个性化他们最喜欢的菜肴并付款，
    secondDesc1 = "qrOrder.secondDesc1",
    // 扫描二维码并从表中订购！！
    secondTitle = "qrOrder.secondTitle",
    // 你的业务是独一无二的，你的数字卡也是独一无二的。自定义徽标、颜色并选择不同的显示模式。
    secondDesc2 = "qrOrder.secondDesc2",
    // 从桌子上自我编辑
    thirdContainer1Title = "qrOrder.thirdContainer1Title",
    // 它允许您的客户直接从他们的移动设备下订单。只要触摸几下，他们就可以探索菜单，定制订单，并直接将其发送到厨房，而无需等待服务员。
    thirdContainer1Desc = "qrOrder.thirdContainer1Desc",
    // 直接和安全付款
    thirdContainer2Title = "qrOrder.thirdContainer2Title",
    // 它通过允许您的客户直接从您的桌子上付款来简化付款过程。接受各种形式的付款，包括信用卡、借记卡和数字钱包。确保快速、安全和无并发症的支付体验。
    thirdContainer2Desc = "qrOrder.thirdContainer2Desc",
    // 直接在厨房交付的订单。
    thirdContainer2ImagesDesc1 = "qrOrder.thirdContainer2ImagesDesc1",
    thirdContainer2ImagesDesc2 = "qrOrder.thirdContainer2ImagesDesc2",
    // 提高服务效率
    thirdContainer3Title = "qrOrder.thirdContainer3Title",
    // 减少等待时间，优化员工的工作流程。通过自我发布和直接付款，您的服务员可以专注于提供卓越的服务，而不是下单和处理付款。
    thirdContainer3Desc = "qrOrder.thirdContainer3Desc",
    // 轻松订购
    fourthItem1Title = "qrOrder.fourthItem1Title",
    // 只需二维码扫描，您的客户就可以看到完整的菜单，选择他们的菜肴，并将订单直接发送到厨房，而无需等待。
    fourthItem1Desc = "qrOrder.fourthItem1Desc",
    // 品味个性化
    fourthItem2Title = "qrOrder.fourthItem2Title",
    // 我们的平台允许每位食客根据自己的喜好修改菜肴，确保个性化的烹饪体验。
    fourthItem2Desc = "qrOrder.ourthItem2Desc",
    // 从桌子上快速安全地付款
    fourthItem3Title = "qrOrder.fourthItem3Title",
    // 用餐结束后，客户可以使用他们首选的付款方式，通过我们的安全平台支付账单。
    fourthItem3Desc = "qrOrder.fourthItem3Desc",
    // 获取信息
    obtainInfo = "qrOrder.obtainInfo",
    // 一个多月以前评论
    fivethTitle = "qrOrder.fivethTitle",
    // 接受来自您所有在线平台的付款，为您的客户提供从任何设备在线付款的可能性。我们的解决方案旨在帮助您改善转换，优化签出时的用户体验
    fivethDesc1 = "qrOrder.fivethDesc1",
    fivethDesc2 = "qrOrder.fivethDesc2",
    fivethDesc3 = "qrOrder.fivethDesc3",
    fivethDesc4 = "qrOrder.fivethDesc4",
    // 按你想要的方式付款
    sixTitle = "qrOrder.sixTitle",
    // 它还为那些喜欢它的客户提供了在订单结束时现金支付的选择。
    sixDesc = "qrOrder.sixDesc",
    // 免费开始，30天没有承诺
    sevenTitle = "qrOrder.sevenTitle",
    // 发现我们的计划
    discoverOurPlan = "qrOrder.discoverOurPlan",
    // 你想知道更多吗？
    knowMore = "qrOrder.knowMore",
    backOfficeCardDesc = "qroder.backOfficeCardDesc",
    // 查看更多
    readMore = "qroder.readMore",
    cartaDigitalCardTitle = "qroder.cartaDigitalCardTitle",
    cartaDigitalCardDesc = "qroder.cartaDigitalCardDesc",

    // mobile
    qrorderAdios = "qroder.adios",
    qrorderAlas = "qrorder.alas",
    qrorderEsperas = "qrorder.esperas",
}

export default InterQrOrderEnum