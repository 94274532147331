import { ReactComponent as Logo } from "./assets/logo.svg";
import { ReactComponent as ViewList } from "./assets/view-list.svg";
import { ReactComponent as MobileInternet } from "./assets/mobile-internet.svg";

import "./index.less";
import { useNavigate, useParams } from "react-router-dom";

const MobileHeader = () => {
  const navigator = useNavigate();
  const { lang } = useParams();
  return (
    <div className="mobileHeader flex justify-between items-center">
      <Logo
        onClick={() => {
          navigator(`/${lang}/home`);
        }}
      />
      {/* <div>
        <MobileInternet className="mobileInternetIcon" />
        <ViewList />
      </div> */}
    </div>
  );
};
export default MobileHeader;
