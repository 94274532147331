import useTypeList from "./useTypeList";
import GlobalConfig from "@src/GlobalConfig";
import { useNavigate, useParams } from "react-router-dom";
import "../index.less";

const FooterRight = () => {
  const { typeList } = useTypeList();
  const navigator = useNavigate();
  const { lang } = useParams();
  return (
    <div className="footerRight flex justify-around">
      {typeList.map((type: any) => {
        return (
          <div key={type.title}>
            <div className="typeTitle">{type.title}</div>
            {type.list.map((item: any) => {
              return (
                <>
                  {item.type === "img" ? (
                    <div className="accountCodeWrap">
                      <img className="img" src={item.img} />
                      <div className="accountText">{item.name}</div>
                    </div>
                  ) : (
                    <div
                      className={`typeItem flex items-center ${
                        item.path || item.navigate ? "cursor-pointer" : ""
                      }`}
                      key={item.name}
                      onClick={() => {
                        if (item.path) {
                          navigator(`/${lang}${item.path}`);
                        } else if (item.navigate === "develop") {
                          GlobalConfig.pushOpenAPI();
                        }
                      }}
                    >
                      {item.icon && <div className="iconWrap flex items-center">{item.icon}</div>}
                      {item.name}
                    </div>
                  )}
                </>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
export default FooterRight;
