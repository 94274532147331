import InternationalEnum from "@src/Locales/Constants/InternationalEnum";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface IImagesProps {
  src: string;
  alt?: string;
}

interface IReturnRes {
  home: IImagesProps[];
  qrorder: IImagesProps[];
  reservas: IImagesProps[];
  cartaDigital: IImagesProps[];
  tpv: IImagesProps[];
  backOffice: IImagesProps[];
  member: IImagesProps[];
  marketing: IImagesProps[];
  rsInsightAPP: IImagesProps[];
  kds: IImagesProps[];
  quioscos: IImagesProps[];
  restoPara: IImagesProps[];
  sobreNosotros: IImagesProps[];
  tarifas: IImagesProps[];
  contact: IImagesProps[];
  gestionyControl: IImagesProps[];
  delivery: IImagesProps[];
  download: IImagesProps[];
  elecMenu: IImagesProps[];
  globalService: IImagesProps[];
}

type ICurrimages = {
  [K in keyof IReturnRes]: IImagesProps[];
};

type IImageKey = keyof IReturnRes;

const useImagesLanguage = () => {
  const { t, i18n } = useTranslation();

  const transformImagesFillProps = (currImages: ICurrimages) => {
    return Object.entries(currImages)?.reduce((pre, o) => {
      const [key, value] = o;
      pre[key as IImageKey] = value?.map((item) => ({
        ...item,
        alt: t(InternationalEnum.imageLoadingFailed, { ns: "InternationalWebsite" }),
      }));
      return pre;
    }, {} as IReturnRes);
  };

  const getImagesSrcs = (prefix: string, name: string, count: number) => {
    const namePrefix = i18n.language === "en_us" ? "en" : "es";
    const imagesCounts = new Array(count)?.fill(1)?.map((o, index) => {
      try {
        return {
          src: require("../../../assets/international/" +
            `${prefix}/` +
            `${namePrefix}-${name}-${index + 1}` +
            ".png"),
        };
      } catch (e) {
        // 兜底走es图片
        return {
          src: require("../../../assets/international/" +
            `${prefix}/` +
            `${"es"}-${name}-${index + 1}` +
            ".png"),
        };
      }
    });
    return imagesCounts;
  };

  const esImagesUnion = (): IReturnRes => {
    return {
      home: getImagesSrcs("home", "home", 43),
      qrorder: getImagesSrcs("qrOrder", "qrorder", 31),
      reservas: getImagesSrcs("reservas", "reservas", 14),
      cartaDigital: getImagesSrcs("cartaDigital", "carta", 8),
      tpv: getImagesSrcs("tpv", "tpv", 16),
      backOffice: getImagesSrcs("backOffice", "backoffice", 39),
      member: getImagesSrcs("member", "member", 17),
      marketing: getImagesSrcs("marketing", "marketing", 12),
      rsInsightAPP: getImagesSrcs("rsInsightApp", "rsInsightApp", 12),
      kds: getImagesSrcs("kds", "kds", 14),
      quioscos: getImagesSrcs("quioscos", "quioscos", 8),
      restoPara: getImagesSrcs("restoPara", "para", 1),
      sobreNosotros: getImagesSrcs("sobreNosotros", "sobre", 22),
      tarifas: getImagesSrcs("tarifas", "tarifas", 5),
      contact: getImagesSrcs("contact", "contact", 5),
      gestionyControl: getImagesSrcs("gestionyControl", "gestionyControl", 27),
      delivery: getImagesSrcs("deliveryUnionTakeout", "delivery", 12),
      download: getImagesSrcs("downLoadCenter", "download", 12),
      elecMenu: getImagesSrcs("electricMenu", "menu", 5),
      globalService: getImagesSrcs("globalService", "service", 3),
    };
  };

  const res = useMemo(() => {
    return transformImagesFillProps(esImagesUnion());
  }, [i18n.language]);

  return res;
};

export default useImagesLanguage;
