import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import PCFooter from "./PCLayouts/Footer/";
import PCHeader from "./PCLayouts/Header/";
import { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { Button } from "antd";
import "./Main.less";
import InternationalEnum from "@src/Locales/Constants/InternationalEnum";
import { t } from "i18next";
import Loading from "@src/Basic/Loading";
import i18n from "@src/Basic/Language/i18n";
import _ from "lodash";
import MobileFooter from "./MobileLayouts/Footer";
import MobileHeader from "./MobileLayouts/Header";
import { resetViewport } from "@src/Utils/CommonUtils";

const InternationalMain = () => {
  const { pathname, hash } = useLocation();
  const navigator = useNavigate();
  const { lang } = useParams();

  const [cookies, setCookie] = useCookies(["acceptCookie"]);

  const [showCookieSheet, setShowCookieSheet] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [menuCollapse, setMenuCollapse] = useState(false);

  const contentRef = useRef<any>(null);

  useEffect(() => {
    if (contentRef?.current) {
      contentRef.current.scrollTop = 0;
    }
    console.log(73293, pathname);
  }, [pathname]);

  useEffect(() => {
    if (![1, 2].includes(cookies?.acceptCookie)) {
      setShowCookieSheet(true);
    }
  }, [cookies]);

  useEffect(() => {
    const getHeaderHeight = () => {
      const header = document.querySelector<HTMLElement>(".PCHeader");
      if (header) {
        setHeaderHeight(header?.offsetHeight);
      }
    };

    getHeaderHeight();

    window.addEventListener("resize", getHeaderHeight);

    return () => {
      window.removeEventListener("resize", getHeaderHeight);
    };
  }, []);

  const handleSetCookie = (val: number) => {
    const date = new Date();
    // 一个月(毫秒)
    date.setTime(date.getTime() + 30 * 24 * 3600 * 1000);
    setCookie("acceptCookie", val, {
      expires: date,
    });
    setShowCookieSheet(false);
  };

  const handleOnMenuClick = () => {
    const env = i18n.language;
    const menuPath = "/es-private";
    const envPath = env === "es_es" ? menuPath : menuPath.replace("es", "en");
    navigator(`/${lang}${envPath}`);
  };

  const renderCookieSheet = () => {
    return (
      <div className="PCookies z-[10]">
        <div className="PCTextContent">
          <p>
            <span>{t(InternationalEnum.cookieTips1, { ns: "InternationalWebsite" })}</span>
            <span onClick={handleOnMenuClick} className="PCPrivateLink">
              {t(InternationalEnum.cookieTips1SpanLink, { ns: "InternationalWebsite" })}
            </span>
          </p>
          <p>{t(InternationalEnum.cookieTips2, { ns: "InternationalWebsite" })}</p>
        </div>
        <div className="flex items-center justify-end PCCookieButton">
          <Button
            onClick={() => {
              handleSetCookie(1);
            }}
            type="primary"
          >
            {t(InternationalEnum.cookieBtnAccept, { ns: "InternationalWebsite" })}
          </Button>
          <Button
            onClick={() => {
              handleSetCookie(2);
            }}
            type="primary"
          >
            {t(InternationalEnum.cookieBtnReject, { ns: "InternationalWebsite" })}
          </Button>
        </div>
      </div>
    );
  };

  const renderMobileCookieSheet = () => {
    return (
      <div className="PCMobileCookies z-[10]">
        <div className="PCTextContent">
          <p>
            <span>{t(InternationalEnum.cookieTips1, { ns: "InternationalWebsite" })}</span>
            <span onClick={handleOnMenuClick} className="PCPrivateLink">
              {t(InternationalEnum.cookieTips1SpanLink, { ns: "InternationalWebsite" })}
            </span>
          </p>
          <p>{t(InternationalEnum.cookieTips2, { ns: "InternationalWebsite" })}</p>
        </div>
        <div className="flex items-center justify-end PCCookieButton">
          <Button
            onClick={() => {
              handleSetCookie(1);
            }}
            type="primary"
          >
            {t(InternationalEnum.cookieBtnAccept, { ns: "InternationalWebsite" })}
          </Button>
          <Button
            onClick={() => {
              handleSetCookie(2);
            }}
            type="primary"
          >
            {t(InternationalEnum.cookieBtnReject, { ns: "InternationalWebsite" })}
          </Button>
        </div>
      </div>
    );
  };

  const isMobileDownLoadPage = useMemo(() => {
    const path = pathname.split("/").pop();
    return (
      isMobile &&
      path &&
      [
        "download-center",
        "es-legal-notice",
        "es-cookie",
        "es-private",
        "en-legal-notice",
        "en-cookie",
        "en-private",
        // "home",
        // "qr-order",
      ].includes(path)
    );
  }, [isMobile, pathname]);

  useEffect(() => {
    if (isMobileDownLoadPage) {
      // 移动端的下载页面
      resetViewport("width=device-width, initial-scale=1.0");
      setMenuCollapse(false); // 关闭菜单
    } else {
      // 其他页面恢复正常缩放
      resetViewport("width=1440");
    }
  }, [isMobileDownLoadPage]);

  if (isMobileDownLoadPage) {
    return (
      <div className={`international-main w-full h-full`}>
        <BrowserView className="PCHeader">
          <PCHeader />
        </BrowserView>
        <MobileView>
          <MobileHeader menuCollapse={menuCollapse} setMenuCollapse={setMenuCollapse} />
        </MobileView>
        {!menuCollapse && (
          <div
            className="overflow-x-hidden"
            ref={contentRef}
            style={{ marginTop: headerHeight - 10 }}
          >
            <Suspense
              fallback={
                <>
                  <Loading />
                </>
              }
            >
              <Outlet></Outlet>
            </Suspense>
            <BrowserView>
              <PCFooter />
            </BrowserView>
            <MobileView>
              <MobileFooter />
            </MobileView>
          </div>
        )}

        {showCookieSheet && renderMobileCookieSheet()}
      </div>
    );
  }

  return (
    <div className={`international-main w-full h-full PCPageContentWrap`}>
      <BrowserView className="PCHeader">
        <PCHeader />
      </BrowserView>
      <MobileView>
        <PCHeader />
      </MobileView>
      <div className="overflow-x-hidden" ref={contentRef} style={{ marginTop: headerHeight - 10 }}>
        <Suspense
          fallback={
            <>
              <Loading />
            </>
          }
        >
          <Outlet></Outlet>
        </Suspense>
        <BrowserView>
          <PCFooter />
        </BrowserView>
        <MobileView>
          <PCFooter />
        </MobileView>
      </div>

      {showCookieSheet && renderCookieSheet()}
    </div>
  );
};
export default InternationalMain;
